import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "./redux/store";
// import Loader from './screens/Loader/Loader';

const AppRouter = lazy(() => import("../src/redux/routes/route_index"));

function App() {
  return (
    <Provider store={store}>
      <Router>
        {/* <Suspense fallback={<div><Loader/></div>}> */}
        <AppRouter />
        {/* </Suspense> */}
      </Router>
    </Provider>
  );
}

export default App;
