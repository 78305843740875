import { createSlice } from "@reduxjs/toolkit";

export const showLoading = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
      toggleLoading: (state, action) => action.payload,
    },
  });


  