import { createSlice } from "@reduxjs/toolkit";
import {
  userLogin,
  GetDevicesList,
  DeleteDevice,
  GetUsersList,
  GetCardioList,
  GetOtaHistory,
  getPlanHistory,
  cardioFeesUpdation,
  couponsFetch,
  cardioPayment,
  generateCoupons,
  deleteCoupon,
  updateVoyanceLite,
  updateUserPlan,
  getPendingReportList,
  addNewDevice,
} from "../actions/user_action";

const userLoginSlice = createSlice({
  name: "userLogin",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const GetDevicesListSlice = createSlice({
  name: "GetDevicesList",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetDevicesList.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(GetDevicesList.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(GetDevicesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const addNewDeiveSlice = createSlice({
  name: "addNewDevice",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewDevice.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(addNewDevice.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(addNewDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const DeleteDeviceSlice = createSlice({
  name: "DeleteDevice",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteDevice.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteDevice.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(DeleteDevice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const GetUsersListSlice = createSlice({
  name: "GetUsersList",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetUsersList.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(GetUsersList.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(GetUsersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const GetCardioListSlice = createSlice({
  name: "GetCardioList",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCardioList.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(GetCardioList.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(GetCardioList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const GetOtaHistorySlice = createSlice({
  name: "GetOtaHistory",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOtaHistory.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(GetOtaHistory.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(GetOtaHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const getPlanHistorySlice = createSlice({
  name: "getPlanHistory",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlanHistory.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlanHistory.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(getPlanHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const cardioFeesUpdationSlice = createSlice({
  name: "cardioFeesUpdation",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cardioFeesUpdation.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(cardioFeesUpdation.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(cardioFeesUpdation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const couponsFetchSlice = createSlice({
  name: "couponsFetch",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(couponsFetch.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(couponsFetch.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(couponsFetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const cardioPaymentSlice = createSlice({
  name: "cardioPayment",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(cardioPayment.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(cardioPayment.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(cardioPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const generateCouponsSlice = createSlice({
  name: "generateCoupons",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateCoupons.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(generateCoupons.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(generateCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const deleteCouponSlice = createSlice({
  name: "deleteCoupon",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteCoupon.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCoupon.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(deleteCoupon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const updateVoyanceLiteSlice = createSlice({
  name: "updateVoyanceLite",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateVoyanceLite.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(updateVoyanceLite.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(updateVoyanceLite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const updateUserPlanSlice = createSlice({
  name: "updateUserPlan",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserPlan.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserPlan.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(updateUserPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const getPendingReportListSlice = createSlice({
  name: "getPendingReportList",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingReportList.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(getPendingReportList.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(getPendingReportList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

export const reducers = {
  userLogin: userLoginSlice.reducer,
  GetDevicesList: GetDevicesListSlice.reducer,
  addNewDevice: addNewDeiveSlice.reducer,
  GetUsersList: GetUsersListSlice.reducer,
  DeleteDevice: DeleteDeviceSlice.reducer,
  getCardioList: GetCardioListSlice.reducer,
  GetOtaHistory: GetOtaHistorySlice.reducer,
  getPlanHistory: getPlanHistorySlice.reducer,
  cardioFeesUpdation: cardioFeesUpdationSlice.reducer,
  couponsFetch: couponsFetchSlice.reducer,
  cardioPayment: cardioPaymentSlice.reducer,
  generateCoupons: generateCouponsSlice.reducer,
  deleteCoupon: deleteCouponSlice.reducer,
  updateVoyanceLite: updateVoyanceLiteSlice.reducer,
  updateUserPlan: updateUserPlanSlice.reducer,
  pendingReportList: getPendingReportListSlice.reducer,
};
