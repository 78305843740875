import { createSlice } from "@reduxjs/toolkit";
import { binUpload, createNewCardio } from "../actions/bin_action";

const binUploadSlice = createSlice({
  name: "binUpload",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(binUpload.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(binUpload.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(binUpload.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const createNewCardioSlice = createSlice({
  name: "createNewCardio",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createNewCardio.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewCardio.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(createNewCardio.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

export const reducers = {
  binUpload: binUploadSlice.reducer,
  createNewCardio: createNewCardioSlice.reducer,
};
