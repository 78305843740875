import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../constants/config";
import { apiCall } from "../../api/api_index";
import { showLoading } from "../slice/loadingslice";

const createAccountAsyncThunk = (method, type, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    const token = localStorage.getItem("token");
    console.log("data:::", data);
    const apiRequest = {
      method: method,
      headers: token
        ? {
            "Content-Type": "multipart/form-data",
            Authorization: "Token " + localStorage.getItem("token"),
          }
        : {
            "Content-Type": "application/json",
            accept: "application/json",
          },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      // dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
        console.log(`bin action ------ ${type}`, response);
        return response;
      } else {
        console.error(`bin action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  });

export const binUpload = createAccountAsyncThunk(
  "post",
  "binUpload",
  "voyance_admin/ota/ota_file_upload/"
);
export const createNewCardio = createAccountAsyncThunk(
  "post",
  "createNewCardio",
  "voyance_admin/cardio_admin/cardio_account_creation/"
);
