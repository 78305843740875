import axios from "axios";

export const apiCall = (apiRequest) => {
  console.log("apiCall", apiRequest);
  return new Promise((resolve, reject) => {
    axios({
      method: apiRequest.method,
      url: apiRequest.url,
      headers: apiRequest.headers,
      data: apiRequest.data || {},
      // timeout: 70000,
    })
      .then(async (response) => {
        console.log("resp", response);
        resolve(response);
      })
      .catch(async (error) => {
        let response = error.response;
        console.log(error);
        if (response && response.status === 401) {
          console.error(response.data.message);
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/";
          }, 200);
        }
        reject(error);
      });
  });
};
